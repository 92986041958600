.Page {
  background-color: #f4f7fa;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  text-align: center;
  max-width: 400px;
  width: 100%;
}
h1 {
  color: #28a745;
  font-size: 24px;
  margin-bottom: 20px;
}
p {
  color: #555;
  margin-bottom: 30px;
}
.button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}
.button:hover {
  background-color: #218838;
}
footer {
  margin-top: 20px;
  font-size: 12px;
  color: #999;
}
